.only_for_you_carousel_container {
  .ant-carousel {
    .slick-list {
      .slick-slide {
        width: 100px !important;
      }
    }
  }
}
.side-bullet {
  color: #333;
  margin: 0;
  display: block;
  padding-left: 30px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 6px;
    bottom: 6px;
    width: 13px;
    background-color: var(--background-color);
    border-radius: 3px;
    display: inline-block;
  }
}
.text-center {
  text-align: center;
}
.ProductsCarousel {
  .sectionHeadingContainer {
    margin-bottom: 5px !important;
    text-align: left;
    .sectionHeadingBold {
      text-align: left;
      // margin: 10px !important;
      font-size: 26px !important;
      font-family: "ZemestroStd-Medium" !important;
      // padding: 10px !important;
      font-weight: 100 !important;
    }

    .error-404-Desktop {
      text-align: left;
      font-size: 26px !important;
      font-family: "ZemestroStd-Medium" !important;
      font-weight: 100 !important;
      color: brown !important;
    }

    .error-not-found-button {
      border-radius: 100px;
      font-size: 13px;
      height: 49px;
      color: white;
      background-color: rgb(255, 31, 109);
      margin-top: 18px;
      padding: 17px 50px;
    }

    .sectionHeadingMobileBold {
      text-align: left;
      margin: 10px !important;
      font-size: 17px !important;
      font-family: "ZemestroStd-Medium" !important;
      font-weight: 100 !important;
    }

    .error-404-Mobile {
      text-align: left;
      margin: 10px !important;
      font-size: 25px !important;
      font-family: "ZemestroStd-Medium" !important;
      font-weight: 100 !important;
      color: brown !important;
    }

    .sectionHeadingSemiBold {
      text-align: left;
      margin: 10px !important;
      font-size: 24px !important;
      font-family: "ZemestroStd-Medium" !important;
      font-weight: 100 !important;
    }
  }
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.products-carousel-container {
  .slick-next {
    .next-btn-container {
      position: absolute;
      left: -28px;
    }
  }
  .slick-prev {
    .prev-btn-container {
      position: absolute;
      right: -28px;
    }
  }

  .slick-prev:before {
    display: none !important;
  }

  .slick-next:before {
    display: none !important;
  }

  .slick-track {
    display: flex !important;
    gap: 10px;
  }
}
.only_for_you_carousel_container {
  .slick-prev {
    top: -10px;
    right: 35px !important;
    left: unset !important;
  }
  .slick-next {
    top: -10px;
    right: 0 !important;
  }
  .slick-arrow {
    background-color: #fff !important;
    width: 25px;
    height: 25px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    svg {
      width: 18px;
      height: 18px;
      color: var(--background-color);
      display: flex;
      left: 2px;
      position: relative;
    }
  }
}

.products-carousel-mobile-button {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  width: 100%;
  a {
    font-size: 20px;
    color: #d84315 !important;
    font-weight: bold !important;
  }
}

@media screen and (max-width: 767px) {
  .side-bullet {
    color: #333;
    margin: 0;
    display: block;
    padding-left: 30px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      bottom: 6px;
      width: 10px;
      background-color: var(--background-color);
      border-radius: 3px;
      display: inline-block;
    }
  }
}
